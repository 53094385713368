#press{
    // background: radial-gradient(ellipse at center, #443816 0%, black 100%);
    padding-bottom:14em;
        
    background: url('../assets/img/overlay.png'), url('../assets/img/fulls/rude.jpg');
	background-size: 256px 256px, cover;
	background-attachment: fixed, fixed;
	background-position: top left, center center;

    .press-container{
        // &:before{
        //     content: "press reviews (dk. media)";
        //     position: absolute;
        //     left:50%;
        //     transform: translateX(-50%);
        //     border: 1px solid white;
        //     top:-1px;
        //     padding: 0 10px 5px 10px;
        // }
        // border-top: 1px solid white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 190px;
        display: flex;
        overflow-x: auto;

        .quote-wrapper{
            display: flex;
            padding: 25px 0;

            .quote{
                width: 300px;
                padding: 20px 20px;

                .quote__body{
                    font-size: 14px;
                    line-height: 1.4em;
                    font-style: italic;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                }
                .quote__author{
                    font-size: 14px;
                    font-weight: 700;
                    height: 25px;
                }
                a{
                    height: 25px;
                }
            }
        }
    }
}
