/* Tour Calendar */

#tour-calendar {
    display: none; //initial value
    background: url('../assets/img/overlay.png'), url('../assets/img/fulls/festival.jpg');
    background-size: 256px 256px, cover;
    background-attachment: fixed, fixed;
    background-position: top left, center center;

    .tour-container{
        background: rgba(50,50,50,0.6);
        padding:0 0.6em;
        padding: 2em;
        text-align: center;

        @include breakpoint(small){
            padding: 1em;
            &.content{
                max-width: calc(100% - 2em);
            }
        }

        .tour-item{
            transform: translateY(20px);
            opacity: 0;
            transition: transform 700ms, opacity 700ms;
            position: relative;
            background: white;
            margin: 1em;
            padding: 0.8em 2em;
            text-align: left;
            color: #39454b;
            .address{
                min-width: 80px;
                position: relative;
                display: inline-block;
            }
            &.active{
                transform: translateY(0);
                opacity: 1;
            }

            a {
                text-decoration: none;
                span {
                    position: absolute;
                    right: 2em;
                    text-decoration: underline;
                    @include breakpoint(small){
                        position: relative;
                        right: initial;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}