///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

	.image {
		position: relative;
		display: inline-block;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url('../assets/img/overlay.png');
		}

		img {
			display: block;
			width: 100%;
		}

		&.featured {
			display: block;
			width: 100%;
			margin: 0 0 2em 0;
		}

		&.fit {
			display: block;
			width: 100%;
		}

		&.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		&.centered {
			display: block;
			margin: 0 0 2em 0;

			img {
				margin: 0 auto;
				width: auto;
			}
		}
	}