///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Sections/Article */

section{
	border-top: 1px solid rgba(0,0,0,0.5);
}

	header {
		margin-bottom: 1em;

		p {
			display: block;
			margin: 1em 0 0 0;
			padding: 0 0 0.5em 0;
		}
	}

	footer {
		margin-top: 2em;
	}