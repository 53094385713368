///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ul {
		&.default {
			list-style: disc;
			padding-left: 1em;

			li {
				padding-left: 0.5em;
			}
		}

		&.icons {
			cursor: default;

			li {
				display: inline-block;
			}

			a {
				display: inline-block;
				width: 2em;
				height: 2em;
				line-height: 2em;
				text-align: center;
				border: 0;
			}
		}

		&.menu {
			cursor: default;

			li {
				display: inline-block;
				line-height: 1em;
				border-left: solid 1px _palette(border);
				padding: 0 0 0 0.5em;
				margin: 0 0 0 0.5em;

				&:first-child {
					border-left: 0;
					padding-left: 0;
					margin-left: 0;
				}
			}
		}

		&.actions {
			cursor: default;

			li {
				display: inline-block;
				margin: 0 0 0 0.5em;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

	ol.default {
		list-style: decimal;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}