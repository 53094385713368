.custom-music-player{
    background-color: rgba(255,255,255,0.95);
    position: fixed;
    left:-50vw;
    height: calc(100vh - 3em);
    top: 3em;
    z-index: 2;
    width: 50vw;
    transition: transform 200ms ease-out;
    padding-top:1.4em;

    .open{
        position: absolute;
        top:0;
        right: -60px;
        height: 60px;
        width: 60px;
        border: 2px solid rgba(255,255,255,0.75);
        background-color: rgba(255,255,255,0.15);
        border-top: 0;
        border-left: 0;
        border-radius: 0 0 1.2em 0;
        padding: 0 0.5em .5em  0;
        cursor: pointer;
        
        svg{
            transition: opacity 200ms;
            // fill: _palette(accent1, bg);
            fill: white;
            transition: all 300ms;
        }
        
        transition: all 300ms;
        &:hover{
            background-color: rgba(255,255,255,0.25);
        }
    }
    
    .close{
        position: absolute;
        right: 0;
        top:0;
        font-size: 30px;
        height: 50px;
        width: 50px;
        padding: 5px;
        cursor: pointer;
        display: none; 

        svg{
            transition: opacity 200ms;
            fill: #0a0a0a;;
        }
        display: block; 
        left: 50%;
        transform: translateX(-50%);
    }

    &__wrapper{
        padding: 2em;
        overflow: auto;
        width: 100%;
        height: 100%;

        iframe{
            width: 100%;
            height: 100%;
        }
    }

    &.-is-open{
        transform: translateX(50vw);
        .open svg{
            fill: white;
        }
    }

    @include breakpoint(medium){
        top: 3em;
        width: 90vw;
        left:-90vw;
        &.-is-open{
            transform: translateX(90vw);
        }
        .open{
            right: -50px;
            height: 50px;
            width: 50px;
        }
    }
    @include breakpoint(small){
        top: 2.5em;
        width: 100vw;
        left:-100vw;
        padding-top:1.4em;
        &.-is-open{
            transform: translateX(100vw);
            
            .close{
                display: block; 
                left: 50%;
                transform: translateX(-50%);
            }
            .open{
                right: -40px;
                height: 40px;
                width: 40px;
                
            }
        }
    }
}