
.slick-slider{
  	text-align: left;

	.my-slide{
		background-color: white;
		color: #39454b;
		position: relative;
		.slider-image {
			background-size: cover;
			padding-bottom: 50%;
			width: 100%;

			@include breakpoint(xxlarge) {
			  width: 50%;
			}

			@include breakpoint(small) {
			  width: 100%;
			}
		}

		&.img-slide-1 .slider-image{
		  background-image: url('../assets/img/thumbs/nilou.jpg');
		}
		&.img-slide-2 .slider-image{
		  background-image: url('../assets/img/thumbs/rude.jpg');
		}
		&.img-slide-3 .slider-image{
		  background-image: url('../assets/img/thumbs/alfkil.jpg');
		}
		&.img-slide-4 .slider-image{
		  background-image: url('../assets/img/thumbs/emil.jpg');
		  background-position: center;
		}

		.description{
			padding: 1em;

			p{
				margin-bottom: 0;
			}

			@include breakpoint(xxlarge) {
				position: absolute;
				top:0;
				right: 0;
				left: 50%;
			}

			@include breakpoint(small) {
				position: relative;
				left: inherit;
			}
		}
	}



	//@include breakpoint(xlarge) {
	//	//font-size: 15pt;
	//}
    //
	//@include breakpoint(large) {
	//	//font-size: 13pt;
	//}
    //
    //
    //
	//@include breakpoint(small) {
	//	//font-size: 12pt;
	//	//line-height: 1.5em;
	//}



	button{

		height: 50px;
		width: 50px;
		&.slick-prev{
			left: -60px;
		}

		&.slick-next{
			right: -60px;
		}

	  	border: 2px solid white;

		&:hover{
			background-color:rgba(255,255,255,0.4);
		}
		&:before{
		  font-size: 30px;
		  //color: black;
		}

		border-radius: 50%;

		@include breakpoint( medium ){

			height: 30px;
			width: 30px;

			&.slick-prev{
				left: -40px;
			}

			&.slick-next{
				right: -40px;
			}

			&:before{
				font-size: 20px;
				//color: black;
			}

		}

  }

  .slick-dots{
	li{
	  button{
		&:before{
		  font-size: 46px;
		}
	  }
	}
  }

}


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}