///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	.button,
	button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
		background-color: _palette(accent1, bg);
		border: 0;
		border-radius: 3.5em;
		color: _palette(accent1, fg);
		cursor: pointer;
		display: inline-block;
		height: 3.5em;
		line-height: 3.5em;
		outline: 0;
		padding: 0 2em 0 2em;
		position: relative;
		text-align: center;
		text-decoration: none;

		&.down {
			width: 5em;
			height: 5em;
			line-height: 4.5em;
			padding: 0;
			background-image: url('../assets/img/dark-arrow.svg');
			background-position: center center;
			background-repeat: no-repeat;
			text-indent: -10em;
			overflow: hidden;

			&.anchored {
				bottom: 0;
				border-bottom: 0;
				border-radius: 3em 3em 0 0;
				height: 4.5em;
				margin-left: -2.5em;
			}
		}

		&.anchored {
			position: absolute;
			left: 50%;
		}

		&:hover {
			background-color: lighten(_palette(accent1, bg), 5);
		}

		&:active {
			background-color: darken(_palette(accent1, bg), 5);
		}

		&.style2 {
			background-color: transparent;
			border: solid 2px _palette(border);
			color: inherit;

			&:hover {
				background-color: transparentize(_palette(border), 0.75);
			}

			&:active {
				background-color: transparentize(_palette(border), 0.625);
			}

			&.down {
				background-image: url('../assets/img/arrow.svg');
			}
		}
	}