#video{
    .content{
        .video-container{
            // display: flex;

            .video-wrapper {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                padding-top: 25px;
                height: 0;
                width: 100%;
            }
            .video-wrapper iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .column-1{
                width: 100%;
                margin-bottom: 20px;
            }
            .column-2{
                width: 100%;
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                
                .video-wrapper{
                    padding-bottom: 23.125%; /* 16:9 */
                    width: 100%;
                    &:first-of-type{
                        margin-right: 20px;
                    }
                }
            }
            
            @include breakpoint(small){
                .column-2{
                    display: initial;
                    .video-wrapper {
                        padding-bottom: 56.25%;
                        &:first-of-type{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}