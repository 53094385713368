section {
    &#buyCd{
        padding: 5em 0 5em 0;
        .container{
            .buyCd-container{
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                div.wrapper{
                    text-align: left;
                    padding: 2em;
                    &.text-wrapper{
                        padding-left: 2em;
                    }

                }
                img{
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    transition: transform 600ms;
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 100%;
                    }
                    &:hover{
                        transform: scale(1.05);
                    }
                }
                div.wrapper{
                    width: 50%;
                    max-width: 450px;
                    margin: 0 auto;
                    
                    .cta-button{
                        font-size: 15px;
                        display: block;
                        margin-top: 10px;
                        width: 100%;
                    }

                    .streaming-group{
                        padding:1em 0;
                        display: flex;

                        .item{
                            margin-right: 10px;
                            a{
                                img{
                                    height: 30px;
                                    width: 30px;
                                }
                            }
                        }
                    }
                    
                    @include breakpoint(small){
                        text-align: center;
                        width: 100%;
                        &.text-wrapper{
                            padding-left: 0;
                            margin-bottom: 3em;
                            padding: 0;
                            .cta-button{
                                width: 100%;
                                font-size: 17px;
                                display: block;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}