@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900");
/*
	Big Picture by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Basic */
@-ms-viewport {
  width: device-width;
}

html {
  height: 100%;
}

body {
  background: #ffffff;
  height: 100%;
  min-width: 320px;
}

body.is-loading *, body.is-loading *:before, body.is-loading *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Spinner */
@-moz-keyframes spinner-rotate {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  100% {
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -ms-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spinner-rotate {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  100% {
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -ms-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
  }
}

@-ms-keyframes spinner-rotate {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  100% {
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -ms-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
  }
}

@keyframes spinner-rotate {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  100% {
    -moz-transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    -ms-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
  }
}

/* Loader */
@-moz-keyframes spinner-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spinner-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes spinner-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes spinner-hide {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  99% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  100% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: -1;
  }
}

@-webkit-keyframes spinner-hide {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  99% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  100% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: -1;
  }
}

@-ms-keyframes spinner-hide {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  99% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  100% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: -1;
  }
}

@keyframes spinner-hide {
  0% {
    -moz-transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  99% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: 100001;
  }
  100% {
    -moz-transform: scale(0.5) rotate(360deg);
    -webkit-transform: scale(0.5) rotate(360deg);
    -ms-transform: scale(0.5) rotate(360deg);
    transform: scale(0.5) rotate(360deg);
    color: #e5e6e7;
    z-index: -1;
  }
}

@-moz-keyframes overlay-hide {
  0% {
    opacity: 1;
    z-index: 100000;
  }
  15% {
    opacity: 1;
    z-index: 100000;
  }
  99% {
    opacity: 0;
    z-index: 100000;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-webkit-keyframes overlay-hide {
  0% {
    opacity: 1;
    z-index: 100000;
  }
  15% {
    opacity: 1;
    z-index: 100000;
  }
  99% {
    opacity: 0;
    z-index: 100000;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@-ms-keyframes overlay-hide {
  0% {
    opacity: 1;
    z-index: 100000;
  }
  15% {
    opacity: 1;
    z-index: 100000;
  }
  99% {
    opacity: 0;
    z-index: 100000;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes overlay-hide {
  0% {
    opacity: 1;
    z-index: 100000;
  }
  15% {
    opacity: 1;
    z-index: 100000;
  }
  99% {
    opacity: 0;
    z-index: 100000;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

body {
  text-decoration: none;
}

body:before {
  -moz-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-hide 0.25s ease-in-out forwards !important;
  -webkit-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-hide 0.25s ease-in-out forwards !important;
  -ms-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-hide 0.25s ease-in-out forwards !important;
  animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-hide 0.25s ease-in-out forwards !important;
  -moz-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
  color: #e5e6e7;
  content: '\f1ce';
  cursor: default;
  display: block;
  font-size: 2em;
  height: 2em;
  left: 50%;
  line-height: 2em;
  margin: -1em 0 0 -1em;
  opacity: 0;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 2em;
  z-index: -1;
}

body:after {
  -moz-animation: overlay-hide 1.5s ease-in forwards !important;
  -webkit-animation: overlay-hide 1.5s ease-in forwards !important;
  -ms-animation: overlay-hide 1.5s ease-in forwards !important;
  animation: overlay-hide 1.5s ease-in forwards !important;
  background: #ffffff;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

body.is-loading:before {
  -moz-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-rotate 0.75s infinite linear !important;
  -webkit-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-rotate 0.75s infinite linear !important;
  -ms-animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-rotate 0.75s infinite linear !important;
  animation: spinner-show 1.5s 1 0.25s ease forwards, spinner-rotate 0.75s infinite linear !important;
  z-index: 100001;
}

body.is-loading:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  opacity: 1;
  z-index: 100000;
}

@media (-webkit-min-device-pixel-ratio: 2) {
  body:before {
    line-height: 2.025em;
  }
}

/* Type */
body, input, textarea, select {
  font-family: "Source Sans Pro", "sans-serif";
  font-weight: 300;
  font-size: 18pt;
  line-height: 1.75em;
  color: #39454b;
  letter-spacing: 0.025em;
}

@media screen and (max-width: 1920px) {
  body, input, textarea, select {
    font-size: 17pt;
  }
}

@media screen and (max-width: 1680px) {
  body, input, textarea, select {
    font-size: 15pt;
  }
}

@media screen and (max-width: 1280px) {
  body, input, textarea, select {
    font-size: 13pt;
  }
}

@media screen and (max-width: 1000px) {
  body, input, textarea, select {
    font-size: 13pt;
  }
}

@media screen and (max-width: 736px) {
  body, input, textarea, select {
    font-size: 12pt;
    line-height: 1.5em;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
  color: inherit;
  letter-spacing: -0.0325em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 2.25em;
  line-height: 1.25em;
  letter-spacing: -0.05em;
}

@media screen and (max-width: 736px) {
  h2 {
    font-size: 1.5em;
  }
}

strong, b {
  font-weight: 900;
  color: inherit;
}

em, i {
  font-style: italic;
}

a {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: #98c593;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

hr {
  border: 0;
  border-top: solid 1px #e5e6e7;
}

blockquote {
  border-left: solid 0.5em #e5e6e7;
  padding: 1em 0 1em 2em;
  font-style: italic;
}

p, ul, ol, dl, table {
  margin-bottom: 1em;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../assets/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/../assets/fonts/slick.eot");
  src: url("./fonts/../assets/fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/../assets/fonts/slick.woff") format("woff"), url("./fonts/../assets/fonts/slick.ttf") format("truetype"), url("./fonts/../assets/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Box */
.box {
  background: #ffffff;
  color: #39454b;
  padding: 2em;
}

.box > :last-child {
  margin-bottom: 0;
}

.box.style2 {
  padding: 3.5em 2.5em 3.5em 2.5em;
}

@media screen and (max-width: 736px) {
  .box {
    padding: 1em;
  }
  .box.style2 {
    padding: 1.5em 1.25em 1.5em 1.25em;
    background-color: rgba(255, 255, 255, 0.9);
  }
}

/* Button */
input[type="button"],
input[type="submit"],
input[type="reset"],
.button,
button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  background-color: #98c593;
  border: 0;
  border-radius: 3.5em;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  height: 3.5em;
  line-height: 3.5em;
  outline: 0;
  padding: 0 2em 0 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
}

input[type="button"].down,
input[type="submit"].down,
input[type="reset"].down,
.button.down,
button.down {
  width: 5em;
  height: 5em;
  line-height: 4.5em;
  padding: 0;
  background-image: url("../assets/img/dark-arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -10em;
  overflow: hidden;
}

input[type="button"].down.anchored,
input[type="submit"].down.anchored,
input[type="reset"].down.anchored,
.button.down.anchored,
button.down.anchored {
  bottom: 0;
  border-bottom: 0;
  border-radius: 3em 3em 0 0;
  height: 4.5em;
  margin-left: -2.5em;
}

input[type="button"].anchored,
input[type="submit"].anchored,
input[type="reset"].anchored,
.button.anchored,
button.anchored {
  position: absolute;
  left: 50%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
.button:hover,
button:hover {
  background-color: #a8cea4;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active,
.button:active,
button:active {
  background-color: #88bc82;
}

input[type="button"].style2,
input[type="submit"].style2,
input[type="reset"].style2,
.button.style2,
button.style2 {
  background-color: transparent;
  border: solid 2px #e5e6e7;
  color: inherit;
}

input[type="button"].style2:hover,
input[type="submit"].style2:hover,
input[type="reset"].style2:hover,
.button.style2:hover,
button.style2:hover {
  background-color: rgba(229, 230, 231, 0.25);
}

input[type="button"].style2:active,
input[type="submit"].style2:active,
input[type="reset"].style2:active,
.button.style2:active,
button.style2:active {
  background-color: rgba(229, 230, 231, 0.375);
}

input[type="button"].style2.down,
input[type="submit"].style2.down,
input[type="reset"].style2.down,
.button.style2.down,
button.style2.down {
  background-image: url("../assets/img/arrow.svg");
}

/* Form */
form {
  margin: 0 0 2em 0;
}

form .field {
  margin: 0 0 1.5em 0;
}

form .field.half {
  width: 50%;
  float: left;
  padding: 0 0 0 0.75em;
}

form .field.half.first {
  padding: 0 0.75em 0 0;
}

form > .actions {
  margin: 1.5em 0 0 0 !important;
}

@media screen and (max-width: 736px) {
  form .field {
    margin: 0 0 1em 0;
  }
  form .field.half {
    padding: 0 0 0 0.5em;
  }
  form .field.half.first {
    padding: 0 0.5em 0 0;
  }
  form > .actions {
    margin: 1em 0 0 0 !important;
  }
}

@media screen and (max-width: 480px) {
  form .field.half {
    width: 100%;
    float: none;
    padding: 0;
  }
  form .field.half.first {
    padding: 0;
  }
}

label {
  display: block;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: #39454b;
  display: block;
  width: 100%;
  padding: 0.65em 0.75em;
  background: none;
  border: solid 2px #e5e6e7;
  color: inherit;
  border-radius: 0.5em;
  outline: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  border-color: #9ac8e9;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select {
  line-height: 1.35em;
}

textarea {
  min-height: 8em;
}

::-moz-focus-inner {
  border: 0;
}

::-webkit-input-placeholder {
  opacity: 0.375;
}

:-moz-placeholder {
  opacity: 0.375;
}

::-moz-placeholder {
  opacity: 0.375;
}

:-ms-input-placeholder {
  opacity: 0.375;
}

.formerize-placeholder {
  opacity: 0.375;
}

/* Icon */
.icon {
  text-decoration: none;
}

.icon:before {
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1.25em;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon > .label {
  display: none;
}

@font-face {
  font-family: "iconfont";
  src: url("../assets/fonts/iconfont.eot");
  src: url("../assets/fonts/iconfont.eot?#iefix") format("eot"), url("../assets/fonts/iconfont.woff") format("woff"), url("../assets/fonts/iconfont.ttf") format("truetype"), url("../assets/fonts/iconfont.svg#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'iconfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-t_chart:before {
  content: "\E001";
}

.icon-t_robot:before {
  content: "\E002";
}

.icon-t_sound-off:before {
  content: "\E003";
}

.icon-t_sound-on:before {
  content: "\E004";
}

.icon-t_twitter:before {
  content: "\E005";
}

.icon-t_whistle:before {
  content: "\E006";
}

.icon-t_sound:before {
  content: "\E007";
}

/* Image */
.image {
  position: relative;
  display: inline-block;
}

.image:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/img/overlay.png");
}

.image img {
  display: block;
  width: 100%;
}

.image.featured {
  display: block;
  width: 100%;
  margin: 0 0 2em 0;
}

.image.fit {
  display: block;
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 2em 2em 0;
}

.image.centered {
  display: block;
  margin: 0 0 2em 0;
}

.image.centered img {
  margin: 0 auto;
  width: auto;
}

/* List */
ul.default {
  list-style: disc;
  padding-left: 1em;
}

ul.default li {
  padding-left: 0.5em;
}

ul.icons {
  cursor: default;
}

ul.icons li {
  display: inline-block;
}

ul.icons a {
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border: 0;
}

ul.menu {
  cursor: default;
}

ul.menu li {
  display: inline-block;
  line-height: 1em;
  border-left: solid 1px #e5e6e7;
  padding: 0 0 0 0.5em;
  margin: 0 0 0 0.5em;
}

ul.menu li:first-child {
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}

ul.actions {
  cursor: default;
}

ul.actions li {
  display: inline-block;
  margin: 0 0 0 0.5em;
}

ul.actions li:first-child {
  margin-left: 0;
}

ol.default {
  list-style: decimal;
  padding-left: 1.25em;
}

ol.default li {
  padding-left: 0.25em;
}

/* Sections/Article */
section {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

header {
  margin-bottom: 1em;
}

header p {
  display: block;
  margin: 1em 0 0 0;
  padding: 0 0 0.5em 0;
}

footer {
  margin-top: 2em;
}

/* Table */
table {
  width: 100%;
}

table.default {
  width: 100%;
}

table.default tbody tr:nth-child(2n+2) {
  background: rgba(229, 230, 231, 0.5);
}

table.default td {
  padding: 0.5em 1em 0.5em 1em;
}

table.default th {
  text-align: left;
  font-weight: 900;
  padding: 0.5em 1em 0.5em 1em;
}

table.default thead {
  background: #39454b;
  color: #ffffff;
}

table.default tfoot {
  background: #e5e6e7;
}

/* Poptrox */
.poptrox-popup {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  background: #fff;
  padding-bottom: 3em;
  box-shadow: 0 0.1em 0.15em 0 rgba(0, 0, 0, 0.15);
}

.poptrox-popup .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;
  width: 2em;
  height: 2em;
  display: block;
  font-size: 2em;
}

.poptrox-popup .loader:before {
  -moz-animation: spinner-rotate 0.75s infinite linear !important;
  -webkit-animation: spinner-rotate 0.75s infinite linear !important;
  -ms-animation: spinner-rotate 0.75s infinite linear !important;
  animation: spinner-rotate 0.75s infinite linear !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
  color: #e5e6e7;
  content: '\f1ce';
  cursor: default;
  display: block;
  height: 2em;
  left: 0;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2em;
}

.poptrox-popup .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  height: 3em;
  line-height: 2.8em;
  text-align: center;
  cursor: default;
  z-index: 1;
  font-size: 0.9em;
}

.poptrox-popup .nav-next,
.poptrox-popup .nav-previous {
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.01);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.poptrox-popup .nav-next:before,
.poptrox-popup .nav-previous:before {
  content: '';
  position: absolute;
  width: 96px;
  height: 64px;
  background: url("../assets/img/poptrox-nav.svg");
  top: calc(50% - 1.5em);
  margin: -32px 0 0 0;
}

.poptrox-popup:hover .nav-next,
.poptrox-popup:hover .nav-previous {
  opacity: 0.5;
}

.poptrox-popup:hover .nav-next:hover,
.poptrox-popup:hover .nav-previous:hover {
  opacity: 1.0;
}

.poptrox-popup .nav-previous:before {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  filter: FlipH;
}

.poptrox-popup .nav-next {
  right: 0;
}

.poptrox-popup .nav-next:before {
  right: 0;
}

.poptrox-popup .nav-previous {
  left: 0;
}

.poptrox-popup .nav-previous:before {
  left: 0;
}

.poptrox-popup .closer {
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  text-indent: -9999px;
  z-index: 2;
  opacity: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.poptrox-popup .closer:before {
  content: '';
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px #fff;
  background: url("../assets/img/poptrox-closer.svg") center center;
  color: #ffffff !important;
}

.poptrox-popup:hover .closer {
  opacity: 0.5;
}

.poptrox-popup:hover .closer:hover {
  opacity: 1.0;
}

body.is-touch .poptrox-popup .nav-next,
body.is-touch .poptrox-popup .nav-previous,
body.is-touch .poptrox-popup .closer {
  opacity: 1.0 !important;
}

@media screen and (max-width: 736px) {
  .poptrox-popup .nav-next:before,
  .poptrox-popup .nav-previous:before {
    width: 48px;
    height: 32px;
    background-size: contain;
    margin: -16px 0 0 0;
  }
  .poptrox-popup .closer:before {
    right: 12px;
    top: 12px;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 1px #fff;
    background-size: contain;
    opacity: 0.65;
  }
}

/* Gallery */
.gallery {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 45em;
  max-width: 100%;
  margin: 0 auto 2em auto;
}

.gallery article {
  -moz-transition: -moz-transform 1s ease, opacity 1s ease;
  -webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
  -ms-transition: -ms-transform 1s ease, opacity 1s ease;
  transition: transform 1s ease, opacity 1s ease;
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  width: 50%;
  position: relative;
  opacity: 1.0;
}

.gallery article .image {
  margin: 0;
  display: block;
}

.gallery article:nth-last-child(1n) {
  -moz-transition-delay: 0.05s;
  -webkit-transition-delay: 0.05s;
  -ms-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.gallery article:nth-last-child(2n) {
  -moz-transition-delay: 0.05s;
  -webkit-transition-delay: 0.05s;
  -ms-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.gallery article:nth-last-child(2n) {
  -moz-transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.gallery article:nth-last-child(3n) {
  -moz-transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.gallery article:nth-last-child(3n) {
  -moz-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  -ms-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.gallery article:nth-last-child(4n) {
  -moz-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  -ms-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.gallery article:nth-last-child(4n) {
  -moz-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.gallery article:nth-last-child(5n) {
  -moz-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.gallery article:nth-last-child(5n) {
  -moz-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.gallery article:nth-last-child(6n) {
  -moz-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.gallery article:nth-last-child(6n) {
  -moz-transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.gallery article:nth-last-child(7n) {
  -moz-transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.gallery article:nth-last-child(7n) {
  -moz-transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.gallery article:nth-last-child(8n) {
  -moz-transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  -ms-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.gallery article:nth-last-child(8n) {
  -moz-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.gallery article:nth-last-child(9n) {
  -moz-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.gallery article:nth-last-child(9n) {
  -moz-transition-delay: 0.45s;
  -webkit-transition-delay: 0.45s;
  -ms-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.gallery article:nth-last-child(10n) {
  -moz-transition-delay: 0.45s;
  -webkit-transition-delay: 0.45s;
  -ms-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.gallery article:nth-last-child(10n) {
  -moz-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.gallery article:nth-last-child(11n) {
  -moz-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.gallery article:nth-last-child(11n) {
  -moz-transition-delay: 0.55s;
  -webkit-transition-delay: 0.55s;
  -ms-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.gallery article:nth-last-child(12n) {
  -moz-transition-delay: 0.55s;
  -webkit-transition-delay: 0.55s;
  -ms-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.gallery article:nth-last-child(12n) {
  -moz-transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  -ms-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.gallery article:nth-last-child(13n) {
  -moz-transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  -ms-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.gallery article:nth-last-child(13n) {
  -moz-transition-delay: 0.65s;
  -webkit-transition-delay: 0.65s;
  -ms-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.gallery article:nth-last-child(14n) {
  -moz-transition-delay: 0.65s;
  -webkit-transition-delay: 0.65s;
  -ms-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.gallery article:nth-last-child(14n) {
  -moz-transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  -ms-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.gallery article:nth-last-child(15n) {
  -moz-transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  -ms-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.gallery article:nth-last-child(15n) {
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.gallery article:nth-last-child(16n) {
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.gallery article:nth-last-child(16n) {
  -moz-transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  -ms-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.gallery article:nth-last-child(17n) {
  -moz-transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  -ms-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.gallery article:nth-last-child(17n) {
  -moz-transition-delay: 0.85s;
  -webkit-transition-delay: 0.85s;
  -ms-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.gallery article:nth-last-child(18n) {
  -moz-transition-delay: 0.85s;
  -webkit-transition-delay: 0.85s;
  -ms-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.gallery article:nth-last-child(18n) {
  -moz-transition-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
  -ms-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.gallery article:nth-last-child(19n) {
  -moz-transition-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
  -ms-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.gallery article:nth-last-child(19n) {
  -moz-transition-delay: 0.95s;
  -webkit-transition-delay: 0.95s;
  -ms-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.gallery article:nth-last-child(20n) {
  -moz-transition-delay: 0.95s;
  -webkit-transition-delay: 0.95s;
  -ms-transition-delay: 0.95s;
  transition-delay: 0.95s;
}

.gallery article:nth-last-child(20n) {
  -moz-transition-delay: 1s;
  -webkit-transition-delay: 1s;
  -ms-transition-delay: 1s;
  transition-delay: 1s;
}

.gallery article:nth-last-child(21n) {
  -moz-transition-delay: 1s;
  -webkit-transition-delay: 1s;
  -ms-transition-delay: 1s;
  transition-delay: 1s;
}

.gallery article:nth-last-child(21n) {
  -moz-transition-delay: 1.05s;
  -webkit-transition-delay: 1.05s;
  -ms-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.gallery article:nth-last-child(22n) {
  -moz-transition-delay: 1.05s;
  -webkit-transition-delay: 1.05s;
  -ms-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

.gallery article:nth-last-child(22n) {
  -moz-transition-delay: 1.1s;
  -webkit-transition-delay: 1.1s;
  -ms-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.gallery article:nth-last-child(23n) {
  -moz-transition-delay: 1.1s;
  -webkit-transition-delay: 1.1s;
  -ms-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.gallery article:nth-last-child(23n) {
  -moz-transition-delay: 1.15s;
  -webkit-transition-delay: 1.15s;
  -ms-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.gallery article:nth-last-child(24n) {
  -moz-transition-delay: 1.15s;
  -webkit-transition-delay: 1.15s;
  -ms-transition-delay: 1.15s;
  transition-delay: 1.15s;
}

.gallery.inactive article {
  opacity: 0;
}

.gallery.inactive article.from-left {
  -moz-transform: translateX(-14em);
  -webkit-transform: translateX(-14em);
  -ms-transform: translateX(-14em);
  transform: translateX(-14em);
}

.gallery.inactive article.from-right {
  -moz-transform: translateX(14em);
  -webkit-transform: translateX(14em);
  -ms-transform: translateX(14em);
  transform: translateX(14em);
}

.gallery.inactive article.from-top {
  -moz-transform: translateY(-7em);
  -webkit-transform: translateY(-7em);
  -ms-transform: translateY(-7em);
  transform: translateY(-7em);
}

.gallery.inactive article.from-bottom {
  -moz-transform: translateY(7em);
  -webkit-transform: translateY(7em);
  -ms-transform: translateY(7em);
  transform: translateY(7em);
}

@media screen and (max-width: 480px) {
  .gallery {
    -moz-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .gallery article {
    width: 100%;
  }
}

.slick-slider {
  text-align: left;
}

.slick-slider .my-slide {
  background-color: white;
  color: #39454b;
  position: relative;
}

.slick-slider .my-slide .slider-image {
  background-size: cover;
  padding-bottom: 50%;
  width: 100%;
}

@media screen and (max-width: 1920px) {
  .slick-slider .my-slide .slider-image {
    width: 50%;
  }
}

@media screen and (max-width: 736px) {
  .slick-slider .my-slide .slider-image {
    width: 100%;
  }
}

.slick-slider .my-slide.img-slide-1 .slider-image {
  background-image: url("../assets/img/thumbs/nilou.jpg");
}

.slick-slider .my-slide.img-slide-2 .slider-image {
  background-image: url("../assets/img/thumbs/rude.jpg");
}

.slick-slider .my-slide.img-slide-3 .slider-image {
  background-image: url("../assets/img/thumbs/alfkil.jpg");
}

.slick-slider .my-slide.img-slide-4 .slider-image {
  background-image: url("../assets/img/thumbs/emil.jpg");
  background-position: center;
}

.slick-slider .my-slide .description {
  padding: 1em;
}

.slick-slider .my-slide .description p {
  margin-bottom: 0;
}

@media screen and (max-width: 1920px) {
  .slick-slider .my-slide .description {
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
  }
}

@media screen and (max-width: 736px) {
  .slick-slider .my-slide .description {
    position: relative;
    left: inherit;
  }
}

.slick-slider button {
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 50%;
}

.slick-slider button.slick-prev {
  left: -60px;
}

.slick-slider button.slick-next {
  right: -60px;
}

.slick-slider button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.slick-slider button:before {
  font-size: 30px;
}

@media screen and (max-width: 1000px) {
  .slick-slider button {
    height: 30px;
    width: 30px;
  }
  .slick-slider button.slick-prev {
    left: -40px;
  }
  .slick-slider button.slick-next {
    right: -40px;
  }
  .slick-slider button:before {
    font-size: 20px;
  }
}

.slick-slider .slick-dots li button:before {
  font-size: 46px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-music-player {
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  left: -50vw;
  height: calc(100vh - 3em);
  top: 3em;
  z-index: 2;
  width: 50vw;
  transition: transform 200ms ease-out;
  padding-top: 1.4em;
}

.custom-music-player .open {
  position: absolute;
  top: 0;
  right: -60px;
  height: 60px;
  width: 60px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.15);
  border-top: 0;
  border-left: 0;
  border-radius: 0 0 1.2em 0;
  padding: 0 0.5em .5em  0;
  cursor: pointer;
  transition: all 300ms;
}

.custom-music-player .open svg {
  transition: opacity 200ms;
  fill: white;
  transition: all 300ms;
}

.custom-music-player .open:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.custom-music-player .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 30px;
  height: 50px;
  width: 50px;
  padding: 5px;
  cursor: pointer;
  display: none;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}

.custom-music-player .close svg {
  transition: opacity 200ms;
  fill: #0a0a0a;
}

.custom-music-player__wrapper {
  padding: 2em;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.custom-music-player__wrapper iframe {
  width: 100%;
  height: 100%;
}

.custom-music-player.-is-open {
  transform: translateX(50vw);
}

.custom-music-player.-is-open .open svg {
  fill: white;
}

@media screen and (max-width: 1000px) {
  .custom-music-player {
    top: 3em;
    width: 90vw;
    left: -90vw;
  }
  .custom-music-player.-is-open {
    transform: translateX(90vw);
  }
  .custom-music-player .open {
    right: -50px;
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width: 736px) {
  .custom-music-player {
    top: 2.5em;
    width: 100vw;
    left: -100vw;
    padding-top: 1.4em;
  }
  .custom-music-player.-is-open {
    transform: translateX(100vw);
  }
  .custom-music-player.-is-open .close {
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }
  .custom-music-player.-is-open .open {
    right: -40px;
    height: 40px;
    width: 40px;
  }
}

section#buyCd {
  padding: 5em 0 5em 0;
}

section#buyCd .container .buyCd-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

section#buyCd .container .buyCd-container div.wrapper {
  text-align: left;
  padding: 2em;
}

section#buyCd .container .buyCd-container div.wrapper.text-wrapper {
  padding-left: 2em;
}

section#buyCd .container .buyCd-container img {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: transform 600ms;
}

section#buyCd .container .buyCd-container img:before {
  content: '';
  display: block;
  padding-top: 100%;
}

section#buyCd .container .buyCd-container img:hover {
  transform: scale(1.05);
}

section#buyCd .container .buyCd-container div.wrapper {
  width: 50%;
  max-width: 450px;
  margin: 0 auto;
}

section#buyCd .container .buyCd-container div.wrapper .cta-button {
  font-size: 15px;
  display: block;
  margin-top: 10px;
  width: 100%;
}

section#buyCd .container .buyCd-container div.wrapper .streaming-group {
  padding: 1em 0;
  display: flex;
}

section#buyCd .container .buyCd-container div.wrapper .streaming-group .item {
  margin-right: 10px;
}

section#buyCd .container .buyCd-container div.wrapper .streaming-group .item a img {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 736px) {
  section#buyCd .container .buyCd-container div.wrapper {
    text-align: center;
    width: 100%;
  }
  section#buyCd .container .buyCd-container div.wrapper.text-wrapper {
    padding-left: 0;
    margin-bottom: 3em;
    padding: 0;
  }
  section#buyCd .container .buyCd-container div.wrapper.text-wrapper .cta-button {
    width: 100%;
    font-size: 17px;
    display: block;
    margin-top: 20px;
  }
}

/* Tour Calendar */
#tour-calendar {
  display: none;
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/festival.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

#tour-calendar .tour-container {
  background: rgba(50, 50, 50, 0.6);
  padding: 0 0.6em;
  padding: 2em;
  text-align: center;
}

@media screen and (max-width: 736px) {
  #tour-calendar .tour-container {
    padding: 1em;
  }
  #tour-calendar .tour-container.content {
    max-width: calc(100% - 2em);
  }
}

#tour-calendar .tour-container .tour-item {
  transform: translateY(20px);
  opacity: 0;
  transition: transform 700ms, opacity 700ms;
  position: relative;
  background: white;
  margin: 1em;
  padding: 0.8em 2em;
  text-align: left;
  color: #39454b;
}

#tour-calendar .tour-container .tour-item .address {
  min-width: 80px;
  position: relative;
  display: inline-block;
}

#tour-calendar .tour-container .tour-item.active {
  transform: translateY(0);
  opacity: 1;
}

#tour-calendar .tour-container .tour-item a {
  text-decoration: none;
}

#tour-calendar .tour-container .tour-item a span {
  position: absolute;
  right: 2em;
  text-decoration: underline;
}

@media screen and (max-width: 736px) {
  #tour-calendar .tour-container .tour-item a span {
    position: relative;
    right: initial;
    margin-left: 5px;
  }
}

#press {
  padding-bottom: 14em;
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/rude.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

#press .press-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 190px;
  display: flex;
  overflow-x: auto;
}

#press .press-container .quote-wrapper {
  display: flex;
  padding: 25px 0;
}

#press .press-container .quote-wrapper .quote {
  width: 300px;
  padding: 20px 20px;
}

#press .press-container .quote-wrapper .quote .quote__body {
  font-size: 14px;
  line-height: 1.4em;
  font-style: italic;
  height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

#press .press-container .quote-wrapper .quote .quote__author {
  font-size: 14px;
  font-weight: 700;
  height: 25px;
}

#press .press-container .quote-wrapper .quote a {
  height: 25px;
}

#video .content .video-container .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;
}

#video .content .video-container .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#video .content .video-container .column-1 {
  width: 100%;
  margin-bottom: 20px;
}

#video .content .video-container .column-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#video .content .video-container .column-2 .video-wrapper {
  padding-bottom: 23.125%;
  /* 16:9 */
  width: 100%;
}

#video .content .video-container .column-2 .video-wrapper:first-of-type {
  margin-right: 20px;
}

@media screen and (max-width: 736px) {
  #video .content .video-container .column-2 {
    display: initial;
  }
  #video .content .video-container .column-2 .video-wrapper {
    padding-bottom: 56.25%;
  }
  #video .content .video-container .column-2 .video-wrapper:first-of-type {
    margin-bottom: 20px;
  }
}

/* Header */
#header {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  height: 3em;
  line-height: 3em;
  box-shadow: 0 0 0.15em 0 rgba(0, 0, 0, 0.1);
}

#header h1 {
  position: absolute;
  left: 1em;
  top: 0;
  height: 3em;
  line-height: 3em;
  cursor: default;
}

#header h1 a {
  font-size: 1.25em;
}

#header nav {
  position: absolute;
  right: 0.5em;
  top: 0;
  height: 3em;
  line-height: 3em;
}

#header nav ul {
  margin: 0;
}

#header nav ul li {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
}

#header nav ul li a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 3em;
  line-height: 3em;
  padding: 0 0.5em 0 0.5em;
  outline: 0;
}

@media screen and (max-width: 736px) {
  #header {
    height: 2.5em;
    line-height: 2.5em;
  }
  #header h1 {
    text-align: center;
    position: relative;
    left: 0;
    top: 0;
    height: 2.5em;
    line-height: 2.5em;
  }
  #header h1 a {
    font-size: 1em;
  }
  #header nav {
    display: none;
  }
}

body {
  padding-top: 3em;
}

@media screen and (max-width: 736px) {
  body {
    padding-top: 2.5em;
  }
}

/* Main */
.main {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  position: relative;
  margin: 0;
  overflow-x: hidden;
}

.main > .content {
  width: 45em;
  max-width: calc(100% - 4em);
  margin: 0 auto;
}

.main > .content > :last-child {
  margin-bottom: 0;
}

.main.fullscreen {
  min-height: 100%;
}

.main.style1 {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 3em 0 3em 0;
}

.main.style1 h2 {
  font-size: 4.25em;
  line-height: 1em;
}

.main.style1 > .content {
  -moz-transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  transition: opacity 1s ease;
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1.0;
  margin: 0;
}

.main.style1.inactive > .content {
  opacity: 0;
}

.main.style2 {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 3em 0 3em 0;
  overflow: hidden;
}

.main.style2 > .content {
  -moz-transition: -moz-transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  -ms-transition: -ms-transform 1s ease;
  transition: transform 1s ease;
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  width: 35%;
  margin: 0;
}

.main.style2.left {
  -moz-justify-content: -moz-flex-start;
  -webkit-justify-content: -webkit-flex-start;
  -ms-justify-content: -ms-flex-start;
  justify-content: flex-start;
}

.main.style2.right {
  -moz-justify-content: -moz-flex-end;
  -webkit-justify-content: -webkit-flex-end;
  -ms-justify-content: -ms-flex-end;
  justify-content: flex-end;
}

.main.style2.inactive.left > .content {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.main.style2.inactive.right > .content {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.main.style3 {
  text-align: center;
  padding: 6em 0 6em 0;
}

.main.style3 .content > header {
  margin-bottom: 2em;
}

.main.style3.primary {
  background: #ffffff;
}

.main.style3.secondary {
  background: #f5f6f7;
}

.main.dark {
  color: #ffffff;
}

.main.dark a {
  color: inherit;
}

.main.dark .button.style2 {
  border-color: #ffffff;
}

.main.dark .button.style2:hover {
  background-color: rgba(255, 255, 255, 0.125);
}

.main.dark .button.style2:active {
  background-color: rgba(255, 255, 255, 0.25);
}

.main.dark .button.style2.down {
  background-image: url("../assets/img/dark-arrow.svg");
}

body.is-touch .main {
  background-attachment: scroll !important;
}

@media screen and (max-width: 1920px) {
  .main.style2 .content {
    width: 40%;
  }
}

@media screen and (max-width: 1280px) {
  .main.style2 .content {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .main.style2 .content {
    width: 60%;
  }
}

@media screen and (max-width: 736px) {
  .main.fullscreen {
    height: auto !important;
  }
  .main.style1 {
    padding: 4em 15px 4em 15px;
  }
  .main.style1 h2 {
    font-size: 3em;
  }
  .main.style2 {
    padding: 6em 15px 6em 15px;
  }
  .main.style2:before, .main.style2:after {
    display: none !important;
  }
  .main.style2 .button.anchored {
    display: none;
  }
  .main.style2 .content {
    width: 100%;
    max-width: 100%;
    text-align: center;
    -moz-transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .main.style3 {
    text-align: center;
    padding: 3em 10px 3em 10px;
  }
}

@media screen and (max-width: 480px) {
  .main > .content {
    max-width: calc(100% - 1.5em);
  }
}

/* Footer */
#footer {
  position: relative;
  height: 5em;
  line-height: 5em;
  margin: 0;
  background: #39454b;
  color: rgba(185, 186, 187, 0.5);
  overflow: hidden;
}

#footer a {
  color: inherit;
}

#footer a:hover {
  color: #b9babb;
}

#footer ul.actions {
  position: absolute;
  left: 1em;
  top: 0.25em;
  height: 5em;
  line-height: 5em;
  margin: 0;
}

#footer ul.actions li {
  font-size: 1.25em;
  margin: 0;
}

#footer ul.actions li a {
  padding: 0.5em;
}

#footer ul.menu {
  position: absolute;
  right: 2em;
  top: 0;
  height: 5em;
  line-height: 5em;
  margin: 0;
}

#footer ul.menu li {
  border-left-color: rgba(185, 186, 187, 0.2);
  font-size: 0.9em;
}

@media screen and (max-width: 1000px) {
  #footer {
    height: auto;
    line-height: inherit;
    text-align: center;
    padding: 2em 1em 2em 1em;
  }
  #footer ul.actions {
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    line-height: inherit;
    margin: 0 0 1em 0;
  }
  #footer ul.menu {
    position: relative;
    right: 0;
    top: 0;
    height: auto;
    line-height: inherit;
  }
}

@media screen and (max-width: 736px) {
  #footer ul.menu li {
    border-left: 0;
    padding: 1em 0 0 0;
    margin: 0;
    display: block;
  }
  #footer ul.menu li:first-child {
    padding-top: 0;
  }
  #footer ul.menu li a {
    line-height: 1.5em;
  }
}

/* Intro */
#intro {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/hero_image.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center;
  background-repeat: repeat, no-repeat;
}

/* About */
#about {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/bandOutside.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

.about-container {
  background: rgba(50, 50, 50, 0.6);
  padding: 0 0.6em;
  padding: 2em;
  text-align: left;
}

@media screen and (max-width: 736px) {
  .about-container {
    padding: 1em;
  }
  .about-container.content {
    max-width: calc(100% - 2em);
  }
}

/* Music */
#music {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/rude.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

/* buy cd */
#buyCd {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/coverWide.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

/* Video */
#video {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/instruments_on_floor.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
  padding: 2em 0 6em 0;
}

@media screen and (max-width: 1000px) {
  #video .content {
    max-width: calc(100% - 6em);
  }
}

/* Shows */
#shows {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/festival_color.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

@media screen and (max-width: 1000px) {
  #shows .content {
    max-width: calc(100% - 6em);
  }
}

@media screen and (max-width: 736px) {
  #shows .content {
    max-width: calc(100% - 4em);
  }
}

/* Personnel */
#personnel {
  background: url("../assets/img/overlay.png"), url("../assets/img/fulls/nilou_rude.jpg");
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
}

@media screen and (max-width: 1000px) {
  #personnel .content {
    max-width: calc(100% - 6em);
  }
}

/* Contact */
#contact {
  overflow: hidden;
  padding-bottom: 0;
}

#contact .box {
  -moz-transition: -moz-transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  -ms-transition: -ms-transform 1s ease;
  transition: transform 1s ease;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: relative;
}

#contact.inactive .box {
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

@media screen and (max-width: 736px) {
  #contact .box {
    padding-bottom: 2em;
  }
}
