@font-face {
  font-family: "iconfont";
  src: url('../assets/fonts/iconfont.eot');
  src: url('../assets/fonts/iconfont.eot?#iefix') format('eot'),
    url('../assets/fonts/iconfont.woff') format('woff'),
    url('../assets/fonts/iconfont.ttf') format('truetype'),
    url('../assets/fonts/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], %icon-font {
	font-family: 'iconfont';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-t_chart:before { content: "\E001" }
.icon-t_robot:before { content: "\E002" }
.icon-t_sound-off:before { content: "\E003" }
.icon-t_sound-on:before { content: "\E004" }
.icon-t_twitter:before { content: "\E005" }
.icon-t_whistle:before { content: "\E006" }

.icon-t_sound:before { content: "\E007" }
