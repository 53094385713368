@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900');
// @import 'font-awesome.min';

/*
	Big Picture by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/


	@import "libs/skel";

	@include skel-breakpoints((
		xxlarge: '(max-width: 1920px)',
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 1000px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border'
	));

// Base.

	@import 'base/page';
	@import 'base/typography';

//Slick slider

	@import 'slick-theme';
	@import 'slick';

// Component.


	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import '../assets/fonts/icons';
	@import 'components/image';
	@import 'components/list';
	@import 'components/section';
	@import 'components/table';
	@import 'components/poptrox-popup';
	@import 'components/gallery';
	@import 'components/slider';
	@import 'components/customMusicPlayer';
	@import 'components/buyCd';
	@import 'components/tour';
	@import 'components/press';
	@import 'components/video';


// Layout.

	@import 'layout/header';
	@import 'layout/main';
	@import 'layout/footer';



/* Intro */

	#intro {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/hero_image.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center;
		background-repeat: repeat, no-repeat;
	}


/* About */

	#about {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/bandOutside.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;
	}
		.about-container{
			background: rgba(50,50,50,0.6);
			padding:0 0.6em;
			padding: 2em;
			text-align: left;

			@include breakpoint(small){
				padding: 1em;
				&.content{
					max-width: calc(100% - 2em);
				}
			}
		}

/* Music */

	#music {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/rude.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;
	}

/* buy cd */

	#buyCd {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/coverWide.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;
	}

/* Video */

	#video {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/instruments_on_floor.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;

		padding: 2em 0 6em 0;

		.content{
			@include breakpoint(medium){
				max-width: calc(100% - 6em);
			}
			@include breakpoint(small){
				//max-width: calc(100% - 4em);
			}
		}
	}


/* Shows */

	#shows {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/festival_color.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;

		.content{
			@include breakpoint(medium){
				max-width: calc(100% - 6em);
			}
			@include breakpoint(small){
				//max-width: calc(100% - 6em);
				max-width: calc(100% - 4em);
			}
		}
	}


/* Personnel */

	#personnel {
		background: url('../assets/img/overlay.png'), url('../assets/img/fulls/nilou_rude.jpg');
		background-size: 256px 256px, cover;
		background-attachment: fixed, fixed;
		background-position: top left, center center;
		.content{
			@include breakpoint(medium){
				max-width: calc(100% - 6em);
			}
			@include breakpoint(small){
				//max-width: calc(100% - 6em);
				//max-width: calc(100% - 4em);
			}
		}
	}

/* Contact */

	#contact {
		overflow: hidden;
		padding-bottom: 0;

		.box {
			@include vendor('transition', 'transform #{_duration(fade-in)} ease');
			@include vendor('transform', 'translateY(0)');
			position: relative;
		}

		&.inactive {
			.box {
				@include vendor('transform', 'translateY(100%)');
			}
		}

		@include breakpoint(small) {
			.box {
				padding-bottom: 2em;
			}
		}
	}

//
//.main.style3{
//	.container{
//		transition: opacity 600ms;
//	}
//}
//.main.style3.inactive{
//	.container{
//		opacity: 0;
//		//transform: translateY(1000px);
//
//	}
//}