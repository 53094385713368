///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Gallery */

	.gallery {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		width: 45em;
		max-width: 100%;
		margin: 0 auto _size(element-margin) auto;

		article {
			@include vendor('transition', (
				'transform 1s ease',
				'opacity 1s ease'
			));
			@include vendor('transform', 'translateX(0)');
			width: 50%;
			position: relative;
			opacity: 1.0;

			.image {
				margin: 0;
				display: block;
			}

			@for $i from 1 through 23 {
				&:nth-last-child(#{$i}n) {
					@include vendor('transition-delay', '#{(0.05s * $i)}');
				}

				&:nth-last-child(#{$i + 1}n) {
					@include vendor('transition-delay', '#{(0.05s * $i)}');
				}
			}
		}

		&.inactive {
			article {
				opacity: 0;

				&.from-left {
					@include vendor('transform', 'translateX(-14em)');
				}

				&.from-right {
					@include vendor('transform', 'translateX(14em)');
				}

				&.from-top {
					@include vendor('transform', 'translateY(-7em)');
				}

				&.from-bottom {
					@include vendor('transform', 'translateY(7em)');
				}
			}
		}

		@include breakpoint(xsmall) {
			@include vendor('flex-wrap', 'nowrap');
			@include vendor('flex-direction', 'column');

			article {
				width: 100%;
			}
		}
	}